import React from 'react';

const ServicesStrategicAdvice = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M47.3616 26.3933H20.8594C20.6901 26.3933 20.5278 26.3255 20.4081 26.205C20.2884 26.0845 20.2211 25.921 20.2211 25.7506C20.2211 25.5801 20.2884 25.4167 20.4081 25.2962C20.5278 25.1757 20.6901 25.1079 20.8594 25.1079H46.7233V4.28535H9.11474V8.01273C9.11474 8.18318 9.04744 8.34675 8.92773 8.46728C8.80803 8.5878 8.64572 8.6554 8.47644 8.6554C8.30715 8.6554 8.14484 8.5878 8.02514 8.46728C7.90543 8.34675 7.83813 8.18318 7.83813 8.01273V3.64267C7.83813 3.47223 7.90543 3.30865 8.02514 3.18813C8.14484 3.0676 8.30715 3 8.47644 3H47.3616C47.5309 3 47.6934 3.0676 47.8131 3.18813C47.9328 3.30865 47.9999 3.47223 47.9999 3.64267V25.7506C47.9999 25.835 47.9834 25.9185 47.9513 25.9965C47.9192 26.0744 47.8724 26.1453 47.8131 26.205C47.7538 26.2647 47.6834 26.312 47.606 26.3443C47.5285 26.3766 47.4455 26.3933 47.3616 26.3933Z"
      fill="#FCFCFD"
    />
    <path
      d="M27.9066 45.8533C27.7373 45.8533 27.575 45.7855 27.4553 45.665C27.3356 45.5445 27.2683 45.3811 27.2683 45.2106V25.7247C27.2683 25.5543 27.3356 25.3908 27.4553 25.2703C27.575 25.1498 27.7373 25.082 27.9066 25.082C28.0759 25.082 28.2384 25.1498 28.3581 25.2703C28.4778 25.3908 28.5449 25.5543 28.5449 25.7247V45.2106C28.5449 45.3811 28.4778 45.5445 28.3581 45.665C28.2384 45.7855 28.0759 45.8533 27.9066 45.8533Z"
      fill="#FCFCFD"
    />
    <path
      d="M22.1875 45.8531C22.0781 45.8768 21.9649 45.8768 21.8555 45.8531C21.7832 45.811 21.7203 45.7545 21.6705 45.687C21.6208 45.6195 21.5853 45.5423 21.5661 45.4604C21.547 45.3786 21.5446 45.2937 21.5593 45.2109C21.5739 45.1282 21.6052 45.0492 21.6512 44.979L27.3705 35.2104C27.4314 35.1203 27.5131 35.0466 27.6088 34.9956C27.7045 34.9446 27.8111 34.918 27.9194 34.918C28.0276 34.918 28.1343 34.9446 28.23 34.9956C28.3256 35.0466 28.4075 35.1203 28.4684 35.2104L34.1875 44.979C34.2401 45.0518 34.2769 45.1348 34.2955 45.2227C34.3142 45.3107 34.3143 45.4016 34.2958 45.4896C34.2774 45.5776 34.2409 45.6607 34.1886 45.7336C34.1363 45.8065 34.0693 45.8674 33.9921 45.9126C33.9149 45.9578 33.8291 45.9862 33.7403 45.9959C33.6515 46.0057 33.5617 45.9965 33.4766 45.9691C33.3916 45.9417 33.3133 45.8967 33.2466 45.8368C33.1799 45.777 33.1265 45.7037 33.0897 45.6217L27.9833 36.8043L22.8769 45.6217C22.799 45.7263 22.6912 45.8046 22.568 45.8459C22.4448 45.8873 22.3121 45.8898 22.1875 45.8531Z"
      fill="#FCFCFD"
    />
    <path
      d="M7.71055 19.5311C7.22773 19.5311 6.74958 19.4354 6.30352 19.2493C5.85745 19.0633 5.45216 18.7907 5.11076 18.4469C4.76935 18.1032 4.49858 17.695 4.31382 17.2458C4.12905 16.7967 4.03394 16.3155 4.03394 15.8293V13.7213C4.03394 12.7396 4.42126 11.798 5.11076 11.1037C5.80025 10.4095 6.73545 10.0195 7.71055 10.0195C8.68565 10.0195 9.62085 10.4095 10.3103 11.1037C10.9998 11.798 11.3872 12.7396 11.3872 13.7213V15.8293C11.3872 16.8111 10.9998 17.7527 10.3103 18.4469C9.62085 19.1412 8.68565 19.5311 7.71055 19.5311ZM7.71055 11.3049C7.39538 11.3049 7.08324 11.3673 6.79206 11.4888C6.50088 11.6102 6.23637 11.7883 6.01351 12.0127C5.79065 12.2371 5.61379 12.5034 5.49317 12.7966C5.37256 13.0897 5.31054 13.404 5.31054 13.7213V15.8293C5.31054 16.4702 5.56342 17.0848 6.01351 17.538C6.4636 17.9912 7.07403 18.2458 7.71055 18.2458C8.34707 18.2458 8.95751 17.9912 9.4076 17.538C9.85768 17.0848 10.1106 16.4702 10.1106 15.8293V13.7213C10.1106 13.0805 9.85768 12.4658 9.4076 12.0127C8.95751 11.5595 8.34707 11.3049 7.71055 11.3049Z"
      fill="#FCFCFD"
    />
    <path
      d="M11.7193 45.8526H3.88092C3.71163 45.8526 3.54917 45.7849 3.42946 45.6643C3.30976 45.5438 3.24262 45.3804 3.24262 45.21V35.4926C2.40409 35.4276 1.61633 35.0629 1.02165 34.4641C0.426976 33.8654 0.0645552 33.0722 0 32.228V26.0582C0.00672076 24.4308 0.65183 22.872 1.79475 21.7213C2.93767 20.5705 4.48587 19.921 6.10219 19.9142H21.4214C21.5083 19.9121 21.5947 19.9295 21.6742 19.9651C21.7536 20.0006 21.8243 20.0535 21.881 20.1199C21.9987 20.2649 22.062 20.4469 22.0597 20.6341C21.9798 21.8548 21.457 23.0034 20.5908 23.8613C19.7246 24.7192 18.5753 25.2266 17.3617 25.2871H12.2553V45.21C12.2573 45.364 12.2043 45.5136 12.1059 45.6316C12.0075 45.7495 11.8703 45.828 11.7193 45.8526ZM4.51922 44.5673H11.183V24.6444C11.1794 24.559 11.1935 24.4737 11.2243 24.3941C11.2551 24.3145 11.3019 24.2423 11.3619 24.1818C11.4219 24.1214 11.4939 24.0741 11.5729 24.0431C11.652 24.0121 11.7365 23.9981 11.8213 24.0017H17.5405C18.3197 23.9756 19.0671 23.6844 19.6606 23.1755C20.2542 22.6665 20.6589 21.9699 20.8087 21.1996H6.07663C4.79682 21.1996 3.56943 21.7115 2.66447 22.6227C1.7595 23.5339 1.25104 24.7697 1.25104 26.0582V32.1765C1.31394 32.6888 1.54347 33.1658 1.90368 33.5331C2.26389 33.9004 2.73464 34.1375 3.24262 34.2073V26.4952C3.24262 26.3248 3.30976 26.1613 3.42946 26.0408C3.54917 25.9203 3.71163 25.8525 3.88092 25.8525C4.0502 25.8525 4.21251 25.9203 4.33221 26.0408C4.45192 26.1613 4.51922 26.3248 4.51922 26.4952V44.5673Z"
      fill="#FCFCFD"
    />
    <path
      d="M7.71081 45.8521C7.54152 45.8521 7.37906 45.7844 7.25936 45.6638C7.13965 45.5433 7.07251 45.3799 7.07251 45.2094V34.2325C7.07251 34.0621 7.13965 33.8987 7.25936 33.7781C7.37906 33.6576 7.54152 33.5898 7.71081 33.5898C7.8801 33.5898 8.0424 33.6576 8.16211 33.7781C8.28181 33.8987 8.34911 34.0621 8.34911 34.2325V45.2094C8.34911 45.3799 8.28181 45.5433 8.16211 45.6638C8.0424 45.7844 7.8801 45.8521 7.71081 45.8521Z"
      fill="#FCFCFD"
    />
    <path
      d="M15.3977 15.4022C15.0316 15.3259 14.6956 15.1451 14.4328 14.8829C14.1701 14.6208 13.9925 14.2891 13.9221 13.9297C13.8517 13.5704 13.892 13.1998 14.0377 12.865C14.1835 12.5301 14.428 12.2461 14.7406 12.0491C15.0532 11.8521 15.4195 11.7509 15.7931 11.7584C16.1666 11.7659 16.5307 11.8818 16.8388 12.0913C17.1469 12.3009 17.3851 12.5945 17.5234 12.935C17.6618 13.2756 17.6939 13.6478 17.6156 14.0041C17.5682 14.2194 17.4837 14.4248 17.366 14.612C17.1601 14.9237 16.862 15.1662 16.5105 15.3072C16.1591 15.4483 15.771 15.4814 15.3977 15.4022ZM15.9445 12.7762C15.831 12.7526 15.7139 12.751 15.6002 12.7715C15.4864 12.792 15.3781 12.8342 15.2815 12.8958C15.1849 12.9574 15.1022 13.0372 15.0374 13.1305C14.9727 13.2238 14.9271 13.3288 14.904 13.4395C14.8677 13.6355 14.9023 13.8385 15.0017 14.0138C15.101 14.189 15.2592 14.3258 15.4491 14.4007C15.639 14.4757 15.8489 14.484 16.0432 14.4246C16.2375 14.3651 16.4041 14.2415 16.5144 14.0747C16.5528 13.9836 16.5731 13.8861 16.5743 13.7873C16.6194 13.5733 16.5792 13.3497 16.4622 13.1619C16.3453 12.9742 16.1604 12.8364 15.9445 12.7762Z"
      fill="#FFF500"
    />
    <path
      d="M21.037 17.5575L20.86 17.5206L16.6331 14.8806C16.5234 14.8047 16.4478 14.6908 16.4211 14.5624C16.3944 14.434 16.419 14.3009 16.49 14.1908C16.5239 14.1363 16.569 14.0893 16.6224 14.0526C16.6758 14.0159 16.7366 13.9903 16.8007 13.9774C16.8648 13.9646 16.931 13.9646 16.9953 13.9777C17.0596 13.9907 17.1207 14.0166 17.1747 14.0535L21.401 16.6934C21.4574 16.7293 21.5064 16.7758 21.5442 16.8302C21.5821 16.8846 21.6085 16.9458 21.6219 17.0101C21.6352 17.0744 21.6351 17.1406 21.6219 17.2047C21.6087 17.2687 21.5824 17.3294 21.5447 17.3832C21.4881 17.4587 21.4095 17.5157 21.319 17.5467C21.2284 17.5778 21.1302 17.5815 21.037 17.5575Z"
      fill="#FFF500"
    />
    <path
      d="M21.9391 19.527C21.4537 19.4207 21.029 19.1318 20.7572 18.7232C20.4853 18.3146 20.3883 17.8193 20.4871 17.3448C20.5346 17.1295 20.6191 16.9239 20.7369 16.7368C20.9385 16.4237 21.233 16.1785 21.5815 16.0333C21.93 15.8881 22.316 15.8498 22.6894 15.9234C23.0394 15.9941 23.3607 16.1654 23.6119 16.4154C23.9021 16.6949 24.0935 17.0576 24.1573 17.4496C24.2211 17.8415 24.154 18.2419 23.9661 18.5912C23.7783 18.9406 23.4795 19.2202 23.1144 19.3888C22.7493 19.5574 22.3371 19.6059 21.9391 19.527ZM22.4898 16.8818C22.3133 16.8493 22.1313 16.8688 21.9672 16.9379C21.8031 17.007 21.6645 17.1227 21.5687 17.27C21.5392 17.3636 21.5192 17.4599 21.5089 17.5575C21.4629 17.7779 21.5071 18.0079 21.6322 18.1984C21.7572 18.3889 21.9531 18.5248 22.178 18.5768C22.4072 18.6245 22.6455 18.5814 22.8405 18.457C23.0356 18.3325 23.1714 18.1371 23.2179 17.9134C23.2459 17.7704 23.2367 17.6225 23.1903 17.4835C23.144 17.3444 23.0622 17.2188 22.9531 17.1183C22.8234 16.9996 22.6631 16.9178 22.4898 16.8818Z"
      fill="#FFF500"
    />
    <path
      d="M23.1736 17.2415C23.0783 17.2508 22.9819 17.2307 22.8983 17.1842C22.8084 17.0923 22.7567 16.9714 22.7527 16.8448C22.7488 16.7183 22.7931 16.5954 22.8771 16.4999L28.9473 10.2841C28.9934 10.2372 29.0487 10.1999 29.1099 10.174C29.171 10.1482 29.2367 10.1343 29.3035 10.1333C29.3703 10.1324 29.437 10.1444 29.4994 10.1685C29.5617 10.1926 29.6187 10.2285 29.6671 10.2741C29.7155 10.3196 29.7544 10.3739 29.7814 10.4339C29.8085 10.4939 29.8234 10.5584 29.825 10.6237C29.8266 10.689 29.8148 10.754 29.7907 10.8147C29.7667 10.8754 29.7308 10.9308 29.6847 10.9777L23.6181 17.1741C23.5548 17.2185 23.4818 17.2478 23.4046 17.2595C23.3274 17.2712 23.2483 17.265 23.1736 17.2415Z"
      fill="#FFF500"
    />
    <path
      d="M29.9026 11.4023C29.6588 11.3542 29.4268 11.259 29.2202 11.1225C29.0136 10.9859 28.8365 10.8107 28.6994 10.6071C28.5623 10.4034 28.4682 10.1756 28.4221 9.93671C28.376 9.69782 28.379 9.45273 28.431 9.21595C28.4566 8.95948 28.5369 8.71166 28.6671 8.48853C28.7972 8.26539 28.974 8.07191 29.1862 7.92063C29.3984 7.76934 29.6413 7.66364 29.8989 7.61031C30.1565 7.55699 30.4229 7.5572 30.6815 7.61104C30.9401 7.66488 31.1849 7.77111 31.3998 7.92282C31.6147 8.07453 31.7953 8.26842 31.9294 8.49182C32.0636 8.71521 32.1485 8.96319 32.1788 9.21972C32.209 9.47624 32.1837 9.73552 32.1049 9.9809C32.0586 10.2211 31.962 10.4489 31.8214 10.6501C31.6807 10.8513 31.4989 11.0217 31.2873 11.1506C30.8738 11.396 30.3818 11.4855 29.9026 11.4023ZM30.4534 8.75708C30.3399 8.73344 30.2227 8.73185 30.109 8.75239C29.9953 8.77294 29.8874 8.81532 29.7908 8.87692C29.6942 8.93853 29.6111 9.01812 29.5463 9.1114C29.4816 9.20468 29.4365 9.30974 29.4134 9.4205C29.3787 9.5873 29.3951 9.76084 29.4612 9.91931C29.5273 10.0778 29.6399 10.214 29.7846 10.3107C29.9292 10.4074 30.0999 10.4604 30.2744 10.4628C30.4489 10.4651 30.6192 10.4169 30.7647 10.3241C30.9102 10.2314 31.0242 10.0982 31.0916 9.94154C31.159 9.78489 31.177 9.61172 31.1436 9.44398C31.1103 9.27625 31.0268 9.12147 30.9039 8.9992C30.7811 8.87692 30.6244 8.79268 30.4534 8.75708Z"
      fill="#FFF500"
    />
    <path
      d="M33.3156 14.8941C33.2498 14.8833 33.1867 14.8591 33.1307 14.8234C33.0746 14.7876 33.0269 14.741 32.99 14.6863L30.6028 10.9893C30.5551 10.9364 30.5193 10.8742 30.4982 10.8069C30.477 10.7396 30.4711 10.6689 30.4803 10.5994C30.4896 10.5298 30.514 10.4632 30.552 10.404C30.59 10.3448 30.6406 10.2944 30.7004 10.2562C30.7603 10.218 30.828 10.1929 30.8989 10.1826C30.9698 10.1724 31.0425 10.1772 31.1117 10.1967C31.1809 10.2161 31.2449 10.2498 31.2998 10.2955C31.3547 10.3413 31.3991 10.3979 31.4299 10.4616L33.9035 14.2365C33.9748 14.3448 34.0003 14.4758 33.9742 14.6012C33.948 14.7266 33.8724 14.8365 33.7638 14.9073C33.6945 14.9426 33.617 14.9599 33.5386 14.9576C33.4602 14.9553 33.3834 14.9334 33.3156 14.8941Z"
      fill="#FFF500"
    />
    <path
      d="M34.5812 16.8082C34.5154 16.7973 34.4524 16.7732 34.3963 16.7374C34.3402 16.7016 34.2925 16.655 34.2557 16.6004L31.8684 12.9034C31.8207 12.8505 31.7849 12.7883 31.7638 12.721C31.7427 12.6537 31.7367 12.5829 31.746 12.5134C31.7552 12.4439 31.7797 12.3772 31.8177 12.318C31.8557 12.2588 31.9062 12.2084 31.966 12.1702C32.0259 12.132 32.0936 12.107 32.1645 12.0967C32.2355 12.0864 32.3081 12.0912 32.3773 12.1107C32.4465 12.1302 32.5105 12.1639 32.5654 12.2096C32.6204 12.2553 32.6647 12.312 32.6955 12.3757L35.1691 16.1506C35.2404 16.2589 35.2659 16.3898 35.2398 16.5152C35.2137 16.6407 35.1381 16.7506 35.0294 16.8214C34.9601 16.8566 34.8827 16.874 34.8042 16.8717C34.7258 16.8693 34.649 16.8475 34.5812 16.8082Z"
      fill="#FFF500"
    />
    <path
      d="M35.3439 19.4418C34.8575 19.3405 34.4297 19.0561 34.1534 18.6503C33.8771 18.2446 33.7747 17.7503 33.8683 17.2746C33.9165 17.0302 34.0162 16.7987 34.161 16.5948C34.3058 16.3909 34.4926 16.219 34.7096 16.0899C34.9117 15.9638 35.1375 15.8782 35.3745 15.8384C35.6115 15.7985 35.8549 15.8049 36.0902 15.8574C36.5351 15.9979 36.9127 16.2942 37.1497 16.6889C37.3868 17.0836 37.4667 17.5488 37.3739 17.9945C37.2811 18.4403 37.0222 18.8349 36.6473 19.1022C36.2724 19.3695 35.8079 19.4905 35.3439 19.4418ZM35.8907 16.8158C35.6654 16.7668 35.4303 16.8037 35.2336 16.9189C35.0387 17.052 34.9024 17.2518 34.8507 17.4792C34.8276 17.5899 34.8271 17.7043 34.8492 17.8157C34.8713 17.927 34.9158 18.0332 34.9798 18.1282C35.0438 18.2233 35.1258 18.3052 35.2219 18.3694C35.3179 18.4336 35.4259 18.4789 35.5395 18.5026C35.7687 18.5503 36.007 18.5071 36.202 18.3827C36.3971 18.2583 36.5329 18.0628 36.5794 17.8391C36.626 17.6155 36.5795 17.3819 36.4504 17.19C36.3212 16.9981 36.1199 16.8635 35.8907 16.8158Z"
      fill="#FFF500"
    />
    <path
      d="M36.4908 16.9578C36.3855 16.9332 36.29 16.8784 36.2162 16.8005C36.1279 16.7026 36.0828 16.5748 36.0905 16.4452C36.0983 16.3155 36.1582 16.1946 36.2576 16.1092L43.9535 9.47209C44.0528 9.38669 44.183 9.34369 44.3157 9.35257C44.4484 9.36145 44.5724 9.42144 44.6608 9.51939C44.7491 9.61735 44.7947 9.74522 44.787 9.87487C44.7792 10.0045 44.7188 10.1253 44.6194 10.2107L36.924 16.848C36.8638 16.8944 36.7942 16.9282 36.7197 16.9471C36.6452 16.966 36.5671 16.9696 36.4908 16.9578Z"
      fill="#FFF500"
    />
    <path
      d="M44.5736 11.6765C44.4519 11.6472 44.3442 11.5762 44.2709 11.4765C44.1976 11.3768 44.1636 11.255 44.1742 11.1333L44.284 9.91627L43.0377 9.79678C42.9725 9.79154 42.9091 9.77347 42.8511 9.74352C42.7931 9.71357 42.742 9.67238 42.7006 9.62259C42.6592 9.5728 42.6282 9.5154 42.6101 9.45386C42.592 9.39232 42.5868 9.32796 42.5949 9.26468C42.5998 9.19996 42.618 9.13694 42.648 9.07933C42.6781 9.02173 42.7198 8.97072 42.7704 8.92916C42.821 8.88759 42.8799 8.85641 42.9432 8.83733C43.0065 8.81825 43.0732 8.81165 43.1394 8.818L44.8844 9.0013C44.9495 9.00653 45.013 9.02472 45.071 9.05467C45.129 9.08462 45.1805 9.12579 45.2219 9.17558C45.2634 9.22537 45.2938 9.28279 45.3119 9.34433C45.33 9.40587 45.3352 9.47023 45.3271 9.53351L45.1765 11.2421C45.1718 11.3057 45.1537 11.3676 45.1236 11.424C45.0936 11.4804 45.0521 11.5302 45.0016 11.5702C44.9511 11.6102 44.8927 11.6396 44.83 11.6567C44.7672 11.6738 44.7009 11.6781 44.6361 11.6695L44.5736 11.6765Z"
      fill="#FFF500"
    />
  </svg>
);

export default ServicesStrategicAdvice;
